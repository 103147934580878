body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.swiper-slide img {
  width: 100%;
}

.swiper-wrapper {
  max-height: 500px;
  max-width: 1200px;
}

.menu-icon {
  display: none; /* Varsayılan olarak menü simgelerini gizle */
  cursor: pointer;
}

.menu-icon .line {
  width: 30px;
  height: 4px;
  background-color: #333;
  margin: 5px;
  transition: all 0.3s ease;
}

.menu.open {
  display: block; /* Menü açıldığında görünür hale getir */
}

.menu.open ul {
  display: block; /* Menü açıldığında liste öğelerini görünür hale getir */
}

.menu.open .line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menu.open .line:nth-child(2) {
  opacity: 0;
}

.menu.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

@media (max-width: 1200px) {
  .menu {
    display: none; /* Ekran genişliği 768px'den küçükse menüyü gizle */
  }
  .menuMobile .open {
    display: none;
  }

  .menu-icon {
    display: block; /* Ekran genişliği 768px'den küçükse menü simgelerini göster */
  }
  .swiper-slide img {
    width: 100%;
    object-fit: cover;
    min-height: 230px;
  }
}
