.mainInfo {
  background: #e9e9e9;
  margin-top: 100px;
  border-radius: 50px;
  padding-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
}
.mainInfo span {
  font-family: Montserrat;
  color: orange;
  font-weight: 600;
  font-size: 30px;
  text-align: left;
}
.mainInfo .header {
  padding-left: 100px;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  padding-right: 100px;
}
.mainInfo .header span {
  font-size: 30px;
  font-family: Montserrat;
  font-weight: 600;
}

.mainInfo p {
  font-family: Montserrat;
  font-size: 22px;
  color: #138f6a;
  text-align: center;
  font-weight: 600;
}

.column {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: Montserrat;
  color: #138f6a;
  font-weight: 600;
}

@media only screen and (max-width: 800px) {
  .mainInfo {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .mainInfo .header {
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
  .mainInfo .header span {
    font-size: 24px;
  }
  .mainInfo p {
    font-size: 16px;
    padding: 0 10px;
  }
}
