.mainPrice {
  background: #138f6a;
  border-radius: 40px;
  text-align: center;
  padding: 30px;
  margin-top: 100px;
  margin-right: 30px;
  margin-left: 30px;
}
.mainPrice .info span {
  font-size: 48px;
  font-family: Montserrat;
  margin: 0;
  font-weight: 600;
  color: orange;
}

.mainPrice .info p {
  font-size: 20px;
  color: white;
  font-family: Montserrat;
}
.mainPrice img {
  border-radius: 10px;
  max-width: 50%;
  font-family: Montserrat;
}

.mainPrice .info .infoTitle {
  font-size: 16px;
  font-family: Montserrat;
}
.infoCardList {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 40px;
}
.infoCardList .infoCard {
  padding: 25px;
  background: #ff7f00;
  border-radius: 10px;
  min-width: 120px;
}
.infoCardList .infoCard p {
  color: whitesmoke;
  font-weight: bold;
  font-size: 18px;
}

.iban {
  background: #9b9696;
  width: 40%;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 30px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  color: white;
  font-weight: bold;
}
.iban span {
  margin-bottom: 10px;
  font-size: 18px;
}
.iban p {
  margin: 0;
}
@media only screen and (max-width: 800px) {
  .mainPrice {
    border-radius: 0;
  }
  .infoCardList {
    flex-direction: column;
    align-items: center;
  }
  .mainPrice .info span {
    font-size: 24px;
  }

  .iban {
    width: 80%;
    padding: 10px 0;
  }
  .iban span {
    font-size: 14px;
  }
  .iban p {
    font-size: 14px;
    padding: 0 5px;
  }
  .mainPrice {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
  }
  .mainPrice .info img {
    background-size: cover;
    background-position: center;
    width: 280px;
    height: 200px;
    max-width: none;
  }
  .mainPrice .info p {
    font-size: 14px;
  }

  .mainPrice .info .infoTitle {
    font-size: 12px;
  }
  .infoCardList .infoCard {
    max-width: 140px;
    padding: 15px;
  }
  .infoCardList .infoCard p {
    font-size: 14px;
  }
}
