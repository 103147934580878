.container {
  max-width: 1400px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  padding-top: 20px;
}

.navigation {
  display: flex;
}
.navigation li {
  margin-right: 50px;
  list-style-type: none;
  font-family: "Montserrat";
  font-size: 20px;
  color: #4d4d4d;
  font-weight: bold;
}
.navigation li:hover {
  color: #bfb29c;
}
.firstSection {
  text-align: center;
  margin-top: 20px;
}
.firstSection img {
  border-radius: 100px;
  max-height: 600px;
  width: 70%;
  object-fit: cover;
}

.fourthSection {
  display: grid;
  grid-template-columns: 2fr 1.4fr; /* İlk kart için 3 birim, ikinci kart için 1 birim genişlik */
  gap: 20px;
  margin: 0 auto;
}
.fourthSection .fourtCardInfo {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-family: Montserrat;
}
.fourthSection img {
  object-fit: cover;
  border-radius: 40px;
  max-width: 406px;
  min-height: 500px;
}
.fourthSection p {
  font-size: 48px;
  font-family: Montserrat;
}
.fifthSection {
  text-align: center;
}

.fifthSection .fifthDesktop {
  object-fit: cover;
  width: 100%;
  max-height: 600px;
  border-radius: 100px;
  margin: 50px 0;
  max-width: 1200px;
}

.secondSection {
  display: grid;
  grid-template-columns: 1.4fr 2fr; /* İlk kart için 3 birim, ikinci kart için 1 birim genişlik */
  gap: 20px;
  margin: 0 auto;
  max-width: 1500px;
}
.secondSection .secondCardInfo {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-family: Montserrat;
}
.secondSection .imageInfo {
}
.secondSection img {
  object-fit: contain;
  border-radius: 40px;
  max-width: 406px;
}
.secondSection p {
  font-size: 48px;
}

.thirdSection {
  background: #e5dfd4;
  padding-bottom: 60px;
  border-radius: 100px;
}
.thirdSection p {
  font-size: 48px;
  position: relative;
  top: 40px;
  font-family: Montserrat;
  margin-left: 50px;
}
.thirdSection .cardList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 0 auto;
  position: relative;
  top: 30px;
}
.thirdSection .card {
  min-width: 40%;
  padding: 0 50px;
  font-family: Montserrat;
}
.thirdSection .card h2 {
  font-size: 48px;
}
.thirdSection .card label {
  font-size: 24px;
  font-weight: 500;
}

.thirdSection .card span {
  font-size: 20px;
}
.thirdSection .card p {
  margin: 0;
  margin-bottom: 15px;
}
.sixthSection {
  text-align: center;
}
.sixthSection p {
  font-size: 48px;
  font-family: Montserrat;
  margin: 0;
  text-align: center;
}

.sixthSection .iconList {
  display: flex;
  gap: 30px;
  justify-content: center;
  padding: 40px;
}

.seventhSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; /* İlk kart için 3 birim, ikinci kart için 1 birim genişlik */
  gap: 20px;
  position: relative; /* İçerik ile arka plan arasındaki ilişkiyi sağlar */
  overflow: hidden; /* Taşan içeriği gizler */
  padding-bottom: 100px;
  margin-left: 30px;
  margin-right: 30px;
}
.seventhSection img {
  height: 280px;
  object-fit: cover;
  width: 100%;
  border-radius: 20px;
  transition: transform 0.3s ease; /* Geçiş efekti */
}
.seventhSection img:hover {
  transform: scale(1.1); /* Üzerine gelindiğinde büyütme efekti */
}

.contact {
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding-bottom: 50px;
  align-items: center;
}
.contact p {
  font-size: 48px;
}
.contact span {
  font-size: 24px;
}
@media only screen and (max-width: 798px) {
  .container {
    width: 100%;
  }
  .header {
    margin: 0;
  }
  .header img {
    width: 100px;
  }
  .secondSection {
    text-align: center;
    grid-template-columns: 1fr; /* Sadece bir sütun kullan ve alt alta sırala */
    font-family: Montserrat;
  }
  .secondSection p {
    font-size: 24px;
  }
  .secondSection .secondCardInfo {
    font-size: 16px;
  }
  .secondSection span {
    font-size: 14px;
  }
  .firstSection img {
    max-width: 99%;
    height: auto;
    border-radius: 10px;
  }

  .secondSection img {
    object-fit: contain;
    max-width: 80%;
  }
  .secondSection .imageInfo img {
    max-height: 250px;
  }
  .thirdSection {
    border-radius: 0px;
  }
  .thirdSection .cardList {
    grid-template-columns: 1fr;
    gap: 0px;
  }
  .thirdSection .card {
    padding: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  .thirdSection p {
    font-size: 24px;
    text-align: center;
    margin-left: 0;
  }
  .thirdSection .card h2 {
    font-size: 20px;
  }

  .thirdSection .card label {
    font-size: 16px;
    font-weight: 500;
  }
  .thirdSection .card span {
    font-size: 14px;
  }

  .fourthSection {
    grid-template-columns: 1fr; /* İlk kart için 3 birim, ikinci kart için 1 birim genişlik */
  }

  .fourthSection .fourtCardInfo {
    flex-direction: column;
  }
  .fourthSection .fourtCardInfo p {
    font-size: 24px;
  }
  .fourthSection .fourtCardInfo span {
    font-size: 14px;
    padding: 0 30px;
  }
  .fourthCardImage {
    text-align: center;
  }
  .fourthCardImage img {
    min-height: 0;
    max-height: 200px;
  }
  .fifthMobile {
    padding: 0 30px;
  }
  .fifthMobile img {
    width: 100%;
    max-height: 250px;
  }
  .contact {
    padding-bottom: 20px;
  }
  .contact p {
    font-size: 24px;
    margin-top: 40px;
  }
  .contact span {
    font-size: 16px;
    text-align: center;
  }
  .seventhSection {
    padding: 0 30px;
    grid-template-columns: 1fr;
    margin-right: 0;
    margin-left: 0;
  }
  .seventhSection img {
    max-height: 200px;
  }
  .sixthSection {
    margin-top: 50px;
  }
  .sixthSection p {
    font-size: 24px;
  }

  .sixthSection .iconList {
    padding: 15px;
  }
  .navigation li {
    margin-right: 20px;
    font-size: 16px;
  }
}
