@import "../../styles/variables";
.header img {
  max-width: 293px;
}

.header {
  padding: 0 30px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  font-family: $generalFont;
}
.menu {
  display: flex;
  align-items: center;
}
.menu ul {
  display: flex;
  gap: 20px;
}
.logo {
  padding-top: 13px;
  padding-bottom: 4px;
}
.logo img {
  margin-left: 100px;
  max-width: 150px;
}

.menu li {
  list-style-type: none;
}
.swiper-wrapper {
}
.mySwiper {
  max-width: 1500px;
  border-radius: 10px;
}

.menu li a {
  font-weight: bold;
  color: black;
  text-decoration: none; /* Tüm <a> etiketlerinin alt çizgisini kaldırır */
}
.menu li a:active {
  text-decoration: none; /* tıklanınca alt çizgiyi kaldırır */
  color: black;
}
.menu li a:hover {
  color: #0ea6f1;
}
.menuMobile {
  display: none;
}
.header-top {
  background: black;
  max-height: 50px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  font-family: $generalFont;
}
.header-top .container {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.header-top .container p {
  color: darkgray;
  font-weight: bold;
}

.header-top .container span {
  margin-right: 20px;
}
.header-top .email {
  display: flex;
  align-items: center;
  gap: 10px;
}
.header-top .email p {
  color: darkgray;
  font-weight: bold;
}
.header-top .email span {
  color: white;
}

@media (max-width: 1200px) {
  .menu {
    display: none; /* Ekran genişliği 768px'den küçükse menüyü gizle */
  }
  .header-top {
    display: none;
  }
  .mySwiper {
    border-radius: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 10px;
    padding-right: 20px;
  }
  .menuMobile {
    font-family: $generalFont;
  }
  .menuMobile li {
    list-style-type: none;
  }
  .menuMobile a {
    font-weight: bold;
    color: black;
    font-size: 14px;
    text-decoration: none; /* Tüm <a> etiketlerinin alt çizgisini kaldırır */
  }
  .menuMobile li a:active {
    text-decoration: none; /* tıklanınca alt çizgiyi kaldırır */
    color: black;
  }
  .menuMobile li a:hover {
    color: #0ea6f1;
  }
  .logo img {
    width: 150px;
  }
  .menu-icon {
    display: block; /* Ekran genişliği 768px'den küçükse menü simgelerini göster */
  }
  .menuMobile li a {
    font-size: 13px;
    font-weight: bold;
    width: 100%;
  }
  .menuMobile {
    display: block;
    position: relative;
    z-index: 2;
  }
  .menuMobile li {
    border-top: 1px solid #797777;
    padding: 10px;
    background: #f2f2f2 none repeat scroll 0 0;
  }
  .logo img {
    margin-left: 20px;
    max-width: 100px;
  }
  .menuMobile ul {
    padding: 0;
  }
}
